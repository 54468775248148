@import "../../../styles/variables";

.countdown {
  font-size:   50px;
  font-weight: 300;
  span {
    color: $yellow;
  }
}

@media screen and (max-width: 1024px) {
  .countdown {
    font-size: 40px;
  }
}