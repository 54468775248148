@import "../../../styles/variables";

.thanks {
  h1 {
    margin-bottom: 42px;
  }
  p {
    color: white;
    span {
      font-weight: 900;
      color:       $yellow;
    }
  }
  h4 {
    color: $yellow;
  }
  p {
    font-size:   18px;
    max-width:   550px;
    line-height: 1.3;
  }
  ul {
    display:         flex;
    align-items:     center;
    gap:             20px;
    padding:         0;
    list-style-type: none;
    li {
      width: 28px;
    }
  }
}

@media screen and (max-width: 1024px) {
  .thanks {
    h1 {
      margin-bottom: 30px;
    }
  }
}