@import "../../../styles/variables";

.bold {
  font-weight: 900;
  color:       white !important;
}

.kayoralpha {
  margin-top: 124px;
  .title {
    display:       inline-block;
    margin-bottom: 20px;
    h1 {
      color:          white;
    }
  }
  .black {
    text-align: right;
    p {
      letter-spacing: -1.2px;
      margin-bottom:  0;
      color:          white;
      font-size:      50px;
      font-weight:    300;
      padding:        0 10px;
      position:       relative;
      display:        inline-block;
      margin-left:    auto;
      &:after {
        content:          '';
        position:         absolute;
        z-index:          -1;
        bottom:           4%;
        left:             -2%;
        width:            100%;
        height:           90%;
        background-color: black;
      }
    }
  }
  .content {
    position:        relative;
    z-index:         2;
    display:         flex;
    align-items:     flex-start;
    justify-content: space-between;
    p {
      color: white;
    }
  }
  .drop {
    color:          $yellow;
    font-size:      60px;
    font-weight:    200;
    margin-bottom:  0;
    font-family:    'Libre Franklin', sans-serif;
    margin-top:     -14px;
    margin-left:    -10px;
    letter-spacing: -1.8px;
  }
  .left {
    width: 48%;
    button {
      margin-top:    0;
      margin-bottom: 34px;
    }
    p {
      span {
        font-weight: 900;
        color:       $yellow;
      }
    }
  }
  .right {
    width:          47%;
    display:        flex;
    flex-direction: column;
    align-items:    flex-start;
    position:       relative;
    z-index:        2;
    margin-top:     100px;
    .yellow {
      font-size:     30px;
      font-weight:   900;
      color:         black;
      margin-bottom: 2px;
      padding-right: 40px;
      display:       inline-block;
      position:      relative;
      z-index:       2;
      &:after {
        content:          '';
        position:         absolute;
        z-index:          -1;
        bottom:           14%;
        left:             0;
        width:            100%;
        height:           90%;
        background-color: $yellow;
      }
    }
    .timer {
      display: inline-block;
    }
    .play {
      width:         68px;
      cursor:        pointer;
      transition:    .3s linear;
      margin-bottom: 20px;
      &:hover, &:active {
        opacity: .7;
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .kayoralpha {
    margin-top: 40px;
    .drop {
      margin:    -6px 0 0 -4px;
      font-size: clamp(26px, 7.8vw, 34px);
    }
    .black {
      p {
        font-size: 24px;
      }
    }
    .left {
      width: 56%;
    }
    .right {
      width: 42%;
      .play {
        width: 39px;
      }
      .yellow {
        font-size:      24px;
        letter-spacing: -1px;
        &:after {
          height: 108%;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .kayoralpha {
    .content {
      flex-direction: column-reverse;
    }
    .left {
      width: 100%;
      p {
        line-height: 1.1;
      }
    }
    .right {
      width:         100%;
      max-width:     none;
      margin-top:    20px;
      margin-bottom: 20px;
    }
  }
}